export enum 天干 {
    甲 = "甲",
    乙 = "乙",
    丙 = "丙",
    丁 = "丁",
    戊 = "戊",
    己 = "己",
    庚 = "庚",
    辛 = "辛",
    壬 = "壬",
    癸 = "癸",
}

export const 天干序 = [天干.甲, 天干.乙, 天干.丙, 天干.丁, 天干.戊, 天干.己, 天干.庚, 天干.辛, 天干.壬, 天干.癸];
