import {地支} from "../interfaces/地支";

export type 神煞 = "咸池" | "驿马" | "孤辰" | "寡宿" | "将星" | "亡神" | "劫煞" | "灾煞" | "白虎" | "华盖" | "红鸾" | "天喜";

export const angels: 神煞[] = ["咸池", "驿马", "将星", "红鸾", "天喜"];

export const devils: 神煞[] = ["孤辰", "寡宿", "亡神", "劫煞", "灾煞", "白虎", "华盖"];

export const 神煞表: Record<神煞, Record<地支, 地支>> = {
    咸池: {
        [地支.子]: 地支.酉,
        [地支.丑]: 地支.午,
        [地支.寅]: 地支.卯,
        [地支.卯]: 地支.子,
        [地支.辰]: 地支.酉,
        [地支.巳]: 地支.午,
        [地支.午]: 地支.卯,
        [地支.未]: 地支.子,
        [地支.申]: 地支.酉,
        [地支.酉]: 地支.午,
        [地支.戌]: 地支.卯,
        [地支.亥]: 地支.子,
    },
    将星: {
        [地支.子]: 地支.子,
        [地支.丑]: 地支.酉,
        [地支.寅]: 地支.午,
        [地支.卯]: 地支.卯,
        [地支.辰]: 地支.子,
        [地支.巳]: 地支.酉,
        [地支.午]: 地支.午,
        [地支.未]: 地支.卯,
        [地支.申]: 地支.子,
        [地支.酉]: 地支.酉,
        [地支.戌]: 地支.午,
        [地支.亥]: 地支.卯,
    },
    红鸾: {
        [地支.子]: 地支.卯,
        [地支.丑]: 地支.寅,
        [地支.寅]: 地支.丑,
        [地支.卯]: 地支.子,
        [地支.辰]: 地支.亥,
        [地支.巳]: 地支.戌,
        [地支.午]: 地支.酉,
        [地支.未]: 地支.申,
        [地支.申]: 地支.未,
        [地支.酉]: 地支.午,
        [地支.戌]: 地支.巳,
        [地支.亥]: 地支.辰,
    },
    天喜: {
        [地支.子]: 地支.酉,
        [地支.丑]: 地支.申,
        [地支.寅]: 地支.未,
        [地支.卯]: 地支.午,
        [地支.辰]: 地支.巳,
        [地支.巳]: 地支.辰,
        [地支.午]: 地支.卯,
        [地支.未]: 地支.寅,
        [地支.申]: 地支.丑,
        [地支.酉]: 地支.子,
        [地支.戌]: 地支.亥,
        [地支.亥]: 地支.戌,
    },
    驿马: {
        [地支.子]: 地支.寅,
        [地支.丑]: 地支.亥,
        [地支.寅]: 地支.申,
        [地支.卯]: 地支.巳,
        [地支.辰]: 地支.寅,
        [地支.巳]: 地支.亥,
        [地支.午]: 地支.申,
        [地支.未]: 地支.巳,
        [地支.申]: 地支.寅,
        [地支.酉]: 地支.亥,
        [地支.戌]: 地支.申,
        [地支.亥]: 地支.巳,
    },
    孤辰: {
        [地支.子]: 地支.寅,
        [地支.丑]: 地支.寅,
        [地支.寅]: 地支.巳,
        [地支.卯]: 地支.巳,
        [地支.辰]: 地支.巳,
        [地支.巳]: 地支.申,
        [地支.午]: 地支.申,
        [地支.未]: 地支.申,
        [地支.申]: 地支.亥,
        [地支.酉]: 地支.亥,
        [地支.戌]: 地支.亥,
        [地支.亥]: 地支.寅,
    },
    寡宿: {
        [地支.子]: 地支.戌,
        [地支.丑]: 地支.戌,
        [地支.寅]: 地支.丑,
        [地支.卯]: 地支.丑,
        [地支.辰]: 地支.丑,
        [地支.巳]: 地支.辰,
        [地支.午]: 地支.辰,
        [地支.未]: 地支.辰,
        [地支.申]: 地支.未,
        [地支.酉]: 地支.未,
        [地支.戌]: 地支.未,
        [地支.亥]: 地支.戌,
    },
    亡神: {
        [地支.子]: 地支.亥,
        [地支.丑]: 地支.申,
        [地支.寅]: 地支.巳,
        [地支.卯]: 地支.寅,
        [地支.辰]: 地支.亥,
        [地支.巳]: 地支.申,
        [地支.午]: 地支.巳,
        [地支.未]: 地支.寅,
        [地支.申]: 地支.亥,
        [地支.酉]: 地支.申,
        [地支.戌]: 地支.巳,
        [地支.亥]: 地支.寅,
    },
    劫煞: {
        [地支.子]: 地支.巳,
        [地支.丑]: 地支.寅,
        [地支.寅]: 地支.亥,
        [地支.卯]: 地支.申,
        [地支.辰]: 地支.巳,
        [地支.巳]: 地支.寅,
        [地支.午]: 地支.亥,
        [地支.未]: 地支.申,
        [地支.申]: 地支.巳,
        [地支.酉]: 地支.寅,
        [地支.戌]: 地支.亥,
        [地支.亥]: 地支.申,
    },
    灾煞: {
        [地支.子]: 地支.午,
        [地支.丑]: 地支.卯,
        [地支.寅]: 地支.子,
        [地支.卯]: 地支.酉,
        [地支.辰]: 地支.午,
        [地支.巳]: 地支.卯,
        [地支.午]: 地支.子,
        [地支.未]: 地支.酉,
        [地支.申]: 地支.午,
        [地支.酉]: 地支.卯,
        [地支.戌]: 地支.子,
        [地支.亥]: 地支.酉,
    },
    白虎: {
        [地支.子]: 地支.申,
        [地支.丑]: 地支.酉,
        [地支.寅]: 地支.戌,
        [地支.卯]: 地支.亥,
        [地支.辰]: 地支.子,
        [地支.巳]: 地支.丑,
        [地支.午]: 地支.寅,
        [地支.未]: 地支.卯,
        [地支.申]: 地支.辰,
        [地支.酉]: 地支.巳,
        [地支.戌]: 地支.午,
        [地支.亥]: 地支.未,
    },
    华盖: {
        [地支.子]: 地支.戌,
        [地支.丑]: 地支.丑,
        [地支.寅]: 地支.戌,
        [地支.卯]: 地支.未,
        [地支.辰]: 地支.戌,
        [地支.巳]: 地支.丑,
        [地支.午]: 地支.戌,
        [地支.未]: 地支.未,
        [地支.申]: 地支.戌,
        [地支.酉]: 地支.丑,
        [地支.戌]: 地支.戌,
        [地支.亥]: 地支.未,
    },
};

export const AngelDevilUtil = Object.freeze({
    getAngelDevil: (日支: 地支, 时支: 地支): 神煞[] => {
        return Object.keys(神煞表).filter(name => 神煞表[name as 神煞][日支] === 时支) as 神煞[];
    },
    isAngel: (神煞: 神煞): boolean => {
        return angels.some(_ => 神煞.startsWith(_));
    },
    isDevil: (神煞: 神煞): boolean => {
        return devils.some(_ => 神煞.startsWith(_));
    },
});
