export enum 地支 {
    子 = "子",
    丑 = "丑",
    寅 = "寅",
    卯 = "卯",
    辰 = "辰",
    巳 = "巳",
    午 = "午",
    未 = "未",
    申 = "申",
    酉 = "酉",
    戌 = "戌",
    亥 = "亥",
}

export const 地支序 = [地支.子, 地支.丑, 地支.寅, 地支.卯, 地支.辰, 地支.巳, 地支.午, 地支.未, 地支.申, 地支.酉, 地支.戌, 地支.亥];
