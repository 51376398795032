import {地支} from "./地支";

export type 地支关系 = "刑" | "冲" | "破" | "害" | "六合" | "三合";

export const 地支六合 = {
    子: 地支.丑,
    丑: 地支.子,
    寅: 地支.亥,
    卯: 地支.戌,
    辰: 地支.酉,
    巳: 地支.申,
    午: 地支.未,
    未: 地支.午,
    申: 地支.巳,
    酉: 地支.辰,
    戌: 地支.卯,
    亥: 地支.寅,
};

export const 地支三合 = {
    子: [地支.辰, 地支.申],
    丑: [地支.巳, 地支.酉],
    寅: [地支.午, 地支.戌],
    卯: [地支.未, 地支.亥],
    辰: [地支.子, 地支.申],
    巳: [地支.丑, 地支.酉],
    午: [地支.寅, 地支.戌],
    未: [地支.卯, 地支.亥],
    申: [地支.子, 地支.辰],
    酉: [地支.丑, 地支.巳],
    戌: [地支.寅, 地支.午],
    亥: [地支.卯, 地支.未],
};

export const 地支刑 = {
    子: [地支.卯],
    丑: [地支.未, 地支.戌],
    寅: [地支.巳, 地支.申],
    卯: [地支.子],
    辰: [地支.辰],
    巳: [地支.寅, 地支.申],
    午: [地支.午],
    未: [地支.丑, 地支.戌],
    申: [地支.寅, 地支.巳],
    酉: [地支.酉],
    戌: [地支.丑, 地支.未],
    亥: [地支.亥],
};

export const 地支冲 = {
    子: 地支.午,
    丑: 地支.未,
    寅: 地支.申,
    卯: 地支.酉,
    辰: 地支.戌,
    巳: 地支.亥,
    午: 地支.子,
    未: 地支.丑,
    申: 地支.寅,
    酉: 地支.卯,
    戌: 地支.辰,
    亥: 地支.巳,
};

export const 地支破 = {
    子: 地支.酉,
    丑: 地支.辰,
    寅: 地支.亥,
    卯: 地支.午,
    辰: 地支.丑,
    巳: 地支.申,
    午: 地支.卯,
    未: 地支.戌,
    申: 地支.巳,
    酉: 地支.子,
    戌: 地支.未,
    亥: 地支.寅,
};

export const 地支害 = {
    子: 地支.未,
    丑: 地支.午,
    寅: 地支.巳,
    卯: 地支.辰,
    辰: 地支.卯,
    巳: 地支.寅,
    午: 地支.丑,
    未: 地支.子,
    申: 地支.亥,
    酉: 地支.戌,
    戌: 地支.酉,
    亥: 地支.申,
};
