import {first, last} from "lodash";
import {天干} from "../interfaces/天干";
import {地支} from "../interfaces/地支";

export type ScoreLucky = "大吉" | "吉" | "凶" | "大凶";

export type AstrologicalTimeType = "天显时格" | "五不遇时" | "时干入墓";

export interface ScoreResult {
    lucky: ScoreLucky;
    poem: string;
}

const scoreMap: Record<number, ScoreResult> = {
    13: {lucky: "吉", poem: "十三遁甲见阴阳，西北乾宫老妇娘，手执棉布将小女，此时有应主亨昌。作事出门，推合阴阳，营谋嫁娶，起造营昌，子孙官贵，禄遇时良。"},
    14: {lucky: "凶", poem: "遁甲俱十四，东北有人至，白马或青驴，弓箭身穿紫。有人着紫，或是皂衣，前有大坑，後有白虎，凶恶相及，不宜嫁娶，休去远行，凡事难遇。"},
    15: {lucky: "吉", poem: "南方有少女，牵牛抱婴孩，东方车与马，更有男赶来。日月在前，光明丽天，驷马时从，千事皆好，非但殡葬，亦宜修造。"},
    16: {lucky: "吉", poem: "东北有禽蜚，西北白色衣，老人持杖橛，脱却西黄衣。出遇财珍，兼逢酒食，营谋起造，必遇贵人，交易婚姻，得宜获福。"},
    17: {lucky: "凶", poem: "西北风雨云，西方孝服子，两个驾车人，十七必逢是。死尸在前，病符在後，营谋嫁娶，所求灾咎，若遇此时，不获良久。"},
    18: {lucky: "吉", poem: "南方有少妇，犬子或青驴，东方忽见行，克应必无疑。不宜嫁娶，万事吉昌，若遇此时，必要升官。"},
    19: {lucky: "凶", poem: "鸦鹊并弓弩，铜铁东方舞，更有着白衣，西方唤驴狗。俱是凶兆，不是吉祥，故难动用，守静为良。"},
    20: {lucky: "凶", poem: "北方有人驴，乾坤僧道尼，屠赶猪羊去，诸凶必应之。前有津梁，後有猪羊，若遇此时，万事俱殃。"},
    21: {lucky: "吉", poem: "东方有孝子，更与妇人逢，老人持杖板，西北应乾宫。此时所游，青云上盖，百事吉昌，不敢移改。"},
    22: {lucky: "吉", poem: "求乞持杖子，西方鼓笛声，坤兑应更奇，提笼有妇人。乘车驾马，携酒相看，万事皆吉，必遇平安。"},
    23: {lucky: "凶", poem: "男妇携宝剑，南方执铁瓶，文字并猪羊，毁骂病迎门。万事重迭，难於远行，动则遇侵，静则安宁。"},
    24: {lucky: "吉", poem: "南方送福仙，少女手执钱，兑宫羊又至，物色黑青缠。飞鸟在前，玉士在後，行见长官，嫁娶无咎。"},
    25: {lucky: "吉", poem: "驴子共猿狲，南方有妇人，西方老人至，克应最为灵。见四足物，百事称心，出逢长者，更遇知音。"},
    26: {lucky: "大吉", poem: "北方公文行，南方小儿啼，子孙封官禄，大吉不须疑。以上诸时，此时最吉，但试其事，万无一失。"},
    27: {lucky: "大凶", poem: "白马共紫驴，西方一骑出，东方逢觉人，寇缨来应必。以上诸数，此数最恶，先贤详看，明应不错。"},
};

const score = (value: 天干 | 地支) => {
    switch (value) {
        case 天干.甲:
        case 天干.己:
        case 地支.子:
        case 地支.午:
            return 9;
        case 天干.乙:
        case 天干.庚:
        case 地支.丑:
        case 地支.未:
            return 8;
        case 天干.丙:
        case 天干.辛:
        case 地支.寅:
        case 地支.申:
            return 7;
        case 天干.丁:
        case 天干.壬:
        case 地支.卯:
        case 地支.酉:
            return 6;
        case 天干.戊:
        case 天干.癸:
        case 地支.辰:
        case 地支.戌:
            return 5;
        case 地支.巳:
        case 地支.亥:
            return 4;
    }
};

const totalScore = (bazi: [string, string, string, string]) => {
    const [, , 日柱, 时柱] = bazi;
    const 日干 = first(日柱) as 天干;
    const 日支 = last(日柱) as 地支;
    const 时支 = last(时柱) as 地支;
    return score(日干) + score(日支) + score(时支);
};

export const TravelFormulaUtil = Object.freeze({
    totalScore,
    scoreMap,
});
