import {十二月将, 十二月建} from "../interfaces/十二月建";
import {地支, 地支序} from "../interfaces/地支";

const twelveEvents: 十二月建[] = ["建", "除", "满", "平", "定", "执", "破", "危", "成", "收", "开", "闭"];

const twelveMonthGeneralMap: Record<string, 十二月将> = {
    冬至: "大吉",
    大寒: "神后",
    雨水: "徵明",
    春分: "天魁",
    谷雨: "从魁",
    小满: "传送",
    夏至: "小吉",
    大暑: "胜光",
    处暑: "太乙",
    秋分: "天罡",
    霜降: "太冲",
    小雪: "功曹",
};

const generals = ["神后", "大吉", "功曹", "太冲", "天罡", "太乙", "胜光", "小吉", "传送", "从魁", "天魁", "徵明"];

const poems: string[] = [
    "月建秉执之意，此日宜出行上任、做公职办事，不宜婚姻及开仓之事",
    "除旧布新之意，此日做事宜做万事开端，宜诊病扫除及婚姻之事，不宜上任出行经商迁移之事",
    "过满则溢之意，此日做事宜祭祀、祈福、呈交建议，其余不宜",
    "正立平复之意，此日做事皆平平无奇难出佳绩，尤不宜婚姻",
    "平稳安定之意，此日做事宜做决策之事，宜宴请会友、签订协议、谋算策划，不宜求医上任，安置产业",
    "执正持平之意，此日做事宜守不宜攻，宜营建采购，不宜经营开市、交易纳财、定亲嫁娶",
    "大耗破败之意，此日做事多会损耗诸事不宜，但宜破屋坏垣",
    "持危治乱之意，此日做事宜缓不宜急需三思而後行，宜纳福安床入宅，不宜嫁娶",
    "功成名就之意，此日做事诸事皆吉，宜求医问药、婚姻嫁娶、开市立约、求职上任",
    "收旗卷伞之意，此日做事宜收不宜放，宜修缮、纳财、进口、娶妻，不宜出行",
    "花谢复开之意，此日做事百事荣昌，宜结婚宴请、开渠修建、出行迁移、开市纳财、不宜下葬",
    "闭户息养之意，此日做事宜闭不宜开，宜休整规划，不宜求医手术、娶妻妄动",
];

const getEvent = (月令: 地支, 地支: 地支): 十二月建 => {
    const monthIndex = 地支序.indexOf(月令);
    const dayIndex = 地支序.indexOf(地支);

    if (dayIndex >= monthIndex) {
        return twelveEvents[dayIndex - monthIndex];
    } else {
        return twelveEvents[dayIndex + twelveEvents.length - monthIndex];
    }
};

const getGenerals = (中气: string, 日支: 地支): 十二月将[] => {
    const currentGeneral = twelveMonthGeneralMap[中气];
    const monthIndex = generals.indexOf(currentGeneral);
    const dayIndex = 地支序.indexOf(日支);
    const generalInOrders = [...generals.slice(monthIndex), ...generals.slice(0, monthIndex)];
    return [...generalInOrders.slice(-dayIndex), ...generalInOrders.slice(0, -dayIndex)] as 十二月将[];
};

const getGeneralDiZhi = (general: 十二月将): 地支 => {
    return 地支序[generals.indexOf(general)];
};

const poemOf = (event: 十二月建): string => {
    return poems[twelveEvents.indexOf(event)];
};

export const TwelveEventUtil = Object.freeze({getEvent, getGenerals, getGeneralDiZhi, poemOf});
